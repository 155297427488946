<template>
  <div>
    <div class='main-page-content'>
      <el-form v-model='searchStatus' :inline='true' label-position='right'>
        <el-form-item>
          <el-input v-model='searchStatus.nu' class='input-with-select' clearable
                    placeholder='请输出物流单号' @clear='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model='searchStatus.ischeck' clearable placeholder='请选择是否签收标记'>
            <el-option label='已签收' value='1'></el-option>
            <el-option label='未签收' value='0'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='handleQuery'>查询</el-button>
        </el-form-item>
      </el-form>

      <div v-if="userPermissions.indexOf('logistic_info') !== -1" class='default-table'>
        <el-table
          ref='multipleTable'
          :border='true'
          :data='tableData'
          :header-cell-style='headClass'
          row-key='id'
          style='width: 100%'
          tooltip-effect='dark'>
          <el-table-column
            align='center'
            label='序号'
            type='index'
            width='50'>
          </el-table-column>
          <el-table-column
            align='center'
            label='订阅状态'
            width='80'
            prop='is_sub'>
            <template slot-scope='{row}'>
              {{ row.is_sub === 'Y' ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column
            align='center'
            label='物流单号'
            show-overflow-tooltip
            width='180'
            prop='nu'>
          </el-table-column>
          <el-table-column
            align='center'
            label='快递公司'
            prop='logistic.name'
            width='100'>
          </el-table-column>
          <el-table-column
            align='center'
            label='是否签收标记'
            prop='ischeck'
            width='120'>
            <template slot-scope='{row}'>
              <span :style="row.ischeck==='1'? { color: '#ff3176'}:{}">
                 {{ row.ischeck === '1' ? '已签收' : '未签收' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align='center'
            label='状态'
            prop='_state'
            width='120'>
          </el-table-column>

          <el-table-column
            align='center'
            label='最新进度'
            prop='latest'
          >
          </el-table-column>
          <!--                    <el-table-column-->
          <!--                        align='center'-->
          <!--                        label='物流状态高级状态'-->
          <!--                        prop='route_info'-->
          <!--                        width='80'>-->
          <!--                    </el-table-column>-->
          <el-table-column
            align='center'
            show-overflow-tooltip
            label='创建时间/修改时间'
            width='160'>
            <template slot-scope='{row}'>
              {{ row.created_at }} / {{ row.updated_at }}
            </template>
          </el-table-column>
          <el-table-column
            align='center'
            label='物流进度'
            show-overflow-tooltip
            width='100'>
            <template slot-scope='scope'>
              <el-popover
                  placement='right'
                  popper-class='popperClass'
                  trigger='click'
                  width='400'>
                <div class="popoverTitle"> 物流详情
                </div>
                <logistic-time-line :logisticData="scope.row.data"></logistic-time-line>
                <el-button slot='reference' size='small'
                           v-show='scope.row.data?.length!=0' type='text'> 查看
                </el-button>
              </el-popover>

            </template>
          </el-table-column>

          <el-table-column align='center'
                           label='物流状态' prop='route_info'>
            <template slot-scope='{row}'>
              从  <span>{{ showLogisticStatus(row.route_info.from||{}) }}</span>
              发往 <span> {{ showLogisticStatus(row.route_info.to||{}) }}</span>
              <br/>当前： <span> {{ showLogisticStatus(row.route_info.cur||{}) }}</span>
            </template>
          </el-table-column>

        </el-table>
        <el-row justify='right' type='flex'>
          <el-col :span='24'>
            <Pagination
              :limit.sync='tablePagination.page_size'
              :page.sync='tablePagination.current_page'
              :total='tablePagination.total'
              @pagination='getList' />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import logisticTimeLine from '@/components/LogisticTimeLine.vue'

export default {
  name: 'PublicationIndex',
  components: { PageHeaderLayout, logisticTimeLine },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      searchStatus: {
        nu: '',
        ischeck: null
      },
      // 数组data
      tableData: [],
      //数组页码
      tablePagination: {
        total: 0,
        page_size: 15,
        current_page: 1
      }
      // LogisticsData: Logistics
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getList()
    },
    handleQuery() {
      this.tablePagination.current_page = 1
      this.getList()
    },

    searchCondition() {
      let searchCondition = {
        page_size: this.tablePagination.page_size,
        current_page: this.tablePagination.current_page
      }
      this.searchStatus.nu !== '' ? searchCondition['nu'] = this.searchStatus.nu : ''
      this.searchStatus.ischeck !== null ? searchCondition['ischeck'] = this.searchStatus.ischeck : ''
      return searchCondition
    },
    async getList() {
      let searchCondition = this.searchCondition()
      let { list, pages } = await this.$api.getLogisticsInfoList(searchCondition)
      this.$nextTick(() => {
        list.forEach((i) => {
          if (i.data && typeof i.data === 'string')
            i.data = this.calcGridData(i.data) || []

          i.latest = i.data?.[0]?.context || '暂无进度'
        })

        this.tableData = list
        this.tablePagination = pages
        this.tablePagination.current_page = pages.current_page || 1
        this.tablePagination.page_size = pages.page_size || 1
      })
    },
    calcGridData(row) {
      return row ? JSON.parse(row) : []
    },
    showLogisticStatus(info) {
      return `${info.name || '暂无'}（${info.number || '-'}）`
    },
    //批量样式居中
    headClass() {
      return 'text-align:center'
    }
  }

}
</script>
<style lang='scss'>
#foo > .el-checkbox__input.is-checked .el-checkbox__inner, .foo > * > .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border: 1px solid #409EFF;

}

#foo > * > .el-checkbox__inner:hover, .foo > * > * > .el-checkbox__inner:hover {
  border-color: #409EFF !important;
}

.foo > * > span:last-child {
  display: none;
}

.download {
  display: flex;
  float: right;
  margin-left: 10px;
}

.popoverTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;

  > span {
    font-size: 16px;
    float: right;
    color: red;
    cursor: pointer;
  }
}

.popperClass {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
