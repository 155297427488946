<template>
  <div style="max-height:800px; overflow:scroll;">
    <el-timeline v-if="logisticData.length>0" :reverse='false'>
      <el-timeline-item
          v-for='(activity,index) in logisticData'
          :key='index'
          :hide-timestamp='true'
          :type="activity?.statusCode.slice(0,1)==='3'?'success':''" placement='top'>
        <template>
          <div :class="index===0?'currentState':'LogisticsState'">
            <a>
              {{ activity?.status || '' }}</a>
            {{ activity.time || '' }}
          </div>
        </template>
        <div :class="index===0?'currentInfo':'LogisticsInfo'">
          {{ activity?.context }}
        </div>
      </el-timeline-item>
    </el-timeline>
    <div v-else>
      暂无物流信息
    </div>
  </div>

</template>

<script>
export default {
  name: 'LogisticTimeLine',
  props: {
    logisticData: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.popoverTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;

  > span {
    font-size: 16px;
    float: right;
    color: red;
    cursor: pointer;
  }
}

.LogisticsInfo {
  color: #909399;
  font-size: 12px;
}

.LogisticsState {
  color: #606266;
  font-size: 14px;
  margin: 4px 0;
}

.currentInfo {
  font-size: 14px;
  color: #ff3176;
}

.currentState {
  > a {
    color: #ff3176;
    font-size: 16px;
    margin-right: 6px
  }
}


</style>
